@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fixed{
  /* position: fixed;
  bottom: 1px; */
  z-index: 99999999999999999999999999999999999999999;
  position:fixed;
  bottom:-1px;
  min-width:320px;
  max-width:970px;
  width:100%;
  height:90px;
  left:50%; 
  -webkit-transform: translateX(-50%); 
          transform: translateX(-50%)
}
* {
  font-family: "Roboto", sans-serif;

}
a {
  color: black;
}
/* body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
} */

.container {
  text-align: center;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#container-1 {
  background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(100, 152, 173, 0.3)),
      to(rgba(208, 205, 190, 0.5))
    ),
    url("/office1.jpg");
  background-image: linear-gradient(
      rgba(100, 152, 173, 0.3),
      rgba(208, 205, 190, 0.5)
    ),
    url("/office1.jpg");
  background-repeat: no-repeat;
  background-size: 100%, 125% 80%;
  background-position: 0, 10%;
}

#container-2 {
  background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(163, 205, 204, 0.3)),
      to(rgba(177, 137, 112, 0.3))
    ),
    url("/office2.jpg");
  background-image: linear-gradient(
      rgba(163, 205, 204, 0.3),
      rgba(177, 137, 112, 0.3)
    ),
    url("/office2.jpg");
  background-repeat: no-repeat;
  background-size: 100%, 125% 80%;
  background-position: 0, 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .hide-4-mobile {
    display: none;
  }
}

header {
  position: relative;
  background-color: inherit;
}

#header-head {
  background-color: rgba(100, 152, 173, 0.4);
  border-radius: 30px;
}

header ul {
  background-color: rgba(100, 152, 173, 0.5);
  padding: 0 1em;
  margin: 1em;
  border-radius: 40px;
}

header li {
  list-style: none;
  margin: 0;
  padding: 0.5em;
}

h2 {
  margin-top: 0;
}

#social-icons {
  display: flex;
  justify-content: space-around;
}

#social-icons a {
  color: Black;
}

#social-icons svg {
  font-size: 3em;
}

#portfolio-component {
  height: 90vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.project {
  position: relative;
}

.project:nth-child(odd) {
  background-color: rgba(177, 137, 112, 0.3);
}

.project:nth-child(even) {
  background-color: rgba(163, 205, 204, 0.8);
}

.project-content{
  box-sizing: border-box;
  width: 90%;
  max-width: 650px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 2em;
}

.project:nth-child(odd) .project-content {
  background-color: rgba(177, 137, 112, 0.5);
}

.project:nth-child(even) .project-content{
  background-color: rgba(100, 152, 173, 0.4);
}



.project-image {
  width: 80%;
  min-width: 190px;
  min-height: 190px;
  max-width: 450px;

}

.links {
  display: flex;
  justify-content: space-around;
}

.next-container-button-component {
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.next-container-button-component button {
  background-color: rgb(240, 255, 255, 0.7);
  border: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 53px 5px rgba(0, 0, 0, 0.75);
}

.next-container-button-component button.clicked {
  background-color: rgba(100, 152, 173, 0.5);
}

.next-container-button-component svg {
  font-size: 2em;
}

.technologies-component {
  background-color: rgba(100, 152, 173, 0.6);
  width: 80%;
  padding: 1em;
  border-radius: 20px;
}

.technologies-component h2 {
  font-size: 2.5em;
}

.tech-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
}

.tech-list h3 {
  font-size: 1.4em;
}

.tech-list li {
  list-style: none;
}

.lib-fw {
  display: flex;
  justify-content: space-around;
}

